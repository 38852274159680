import 'lazysizes';
import 'bootstrap/dist/js/bootstrap.bundle';

import './app/carousel';
import modal from './app/modal';
modal.bindHandlers();
// import './app/reveal';

import ElementQueries from 'css-element-queries/src/ElementQueries';
ElementQueries.listen();

