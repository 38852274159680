var Swiper = require('swiper');

var Carousel = function( element, config ) {
    var defaults = {
        slidesPerView: 1,
        slidesPerColumn: 1,
        loop: true,
    
        pagination: {
            el: '.js-carousel-pagination',
        },
    
        navigation: {
            nextEl: '.js-carousel-next',
            prevEl: '.js-carousel-prev'
        }
    };

    var container = this.container = element;
    var settings = this.settings = $.extend(true, defaults, $(element).data(), config);

    if( container.querySelector( '.js-carousel-controls' ) ) {
        settings.navigation.nextEl = container.querySelector( '.js-carousel-controls ' + settings.navigation.nextEl );
        settings.navigation.prevEl = container.querySelector( '.js-carousel-controls ' + settings.navigation.prevEl );
        settings.pagination.el = container.querySelector( '.js-carousel-controls ' + settings.pagination.el );
    } else {
        settings.navigation.nextEl = container.querySelector( settings.navigation.nextEl );
        settings.navigation.prevEl = container.querySelector( settings.navigation.prevEl );
        settings.pagination.el = container.querySelector( settings.pagination.el );
    }

    if ( settings.breakpoint ) {
        var mq = window.matchMedia( '(min-width: ' + settings.breakpoint + ')' );
        mq.addListener( this.handleMediaQuery.bind(this) );
        this.handleMediaQuery( mq );
    } else {
        this.init();
    }
};

Carousel.prototype.init = function() {
    var settings = this.settings;
    var container = this.container;

    this.swiper = new Swiper( container, settings );
};

Carousel.prototype.handleMediaQuery = function( mq ) {
    if ( !mq.matches ) {
       if ( this.swiper !== undefined ) this.swiper.destroy( true, true );
    } else {
       this.init();
    }
 };

var instances = document.querySelectorAll('.js-carousel');

for (var index = 0; index < instances.length; index++) {
    var instance = instances[index];
    new Carousel( instance );
}
