import bootbox from 'bootbox';

var modal = {
    show: function( e ){
        var title = $(e.currentTarget).data('title');
        var $target = $($(e.currentTarget).data('target'));
    
        bootbox.dialog({
            title: title,
            size: 'large',
            message: $target.clone()
        });
    },

    bindHandlers: function(){
        $(document).on('click', '.js-modal', this.show.bind( this ) );
    }
};

export default modal;